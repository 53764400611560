import Field from './Field'
import ImageComponent from '../components/inputs/ImageComponent.vue'

export default class Image extends Field {
    fileUploaded = false;

    fileName = '';

    fileUrl = '';

    folder = '';

    constructor(name, label, folder, value) {
      super(name, label, 'text', value)
      this.folder = folder
    }

    getComponent = () => ImageComponent;

    setValue(value) {
      this.fileName = value.filename
      this.fileUrl = value.file_url
      this.fileUploaded = !value.edited
    }

    getUserValue() {
      return `<img style="display: block;width:100%;max-width: 70px;" src="${this.fileUrl}" alt="${this.fileName}">`
    }

    getValue() {
      return {
        file_url: this.fileUrl,
        filename: this.fileName,
        edited: !this.fileUploaded,
      }
    }

    toJSON() {
      return this.getValue()
    }
}
