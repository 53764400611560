<template>
  <div>
    <b-modal
      ref="agent-model-verify-modal"
      title="Verificar talento"
      ok-title="Verificar"
      :ok-disabled="loading"
      cancel-title="Cancelar"
      size="xl"
      @show="openModal"
      @hidden="closeModal"
      @ok="verify"
    >
      <p
        v-if="loading"
        class="my-4 text-center"
      >
        <b-spinner />
      </p>

      <div v-if="!loading && talentModel">
        <div class="row">
          <div class="col-12 col-md-4">

            <component
              :is="talentModel.findField('first_name').getComponent()"
              :options="talentModel.findField('first_name').getOptions()"
              :field="talentModel.findField('first_name')"
            />
          </div>
          <div class="col-12 col-md-4">
            <component
              :is="talentModel.findField('last_name').getComponent()"
              :options="talentModel.findField('last_name').getOptions()"
              :field="talentModel.findField('last_name')"
            />
          </div>
          <div class="col-12 col-md-4">
            <component
              :is="talentModel.findField('email').getComponent()"
              :options="talentModel.findField('email').getOptions()"
              :field="talentModel.findField('email')"
            />
          </div>

          <div class="col-12 col-md-4">
            <component
              :is="talentModel.findField('phone').getComponent()"
              :options="talentModel.findField('phone').getOptions()"
              :field="talentModel.findField('phone')"
            />
          </div>
          <div class="col-12 col-md-4">
            <component
              :is="talentModel.findField('gender').getComponent()"
              :options="talentModel.findField('gender').getOptions()"
              :field="talentModel.findField('gender')"
            />
          </div>
          <div class="col-12 col-md-4">
            <component
              :is="talentModel.findField('birthday').getComponent()"
              :options="talentModel.findField('birthday').getOptions()"
              :field="talentModel.findField('birthday')"
            />
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-12 col-md-4">
            <component
              :is="talentModel.findField('height').getComponent()"
              :options="talentModel.findField('height').getOptions()"
              :field="talentModel.findField('height')"
            />
          </div>
          <div class="col-12 col-md-4">
            <component
              :is="talentModel.findField('weight').getComponent()"
              :options="talentModel.findField('weight').getOptions()"
              :field="talentModel.findField('weight')"
            />
          </div>
          <div class="col-12 col-md-4">
            <component
              :is="talentModel.findField('with_tattoos').getComponent()"
              :options="talentModel.findField('with_tattoos').getOptions()"
              :field="talentModel.findField('with_tattoos')"
            />
          </div>

          <div class="col-12 col-md-4">
            <component
              :is="talentModel.findField('skin_color').getComponent()"
              :options="talentModel.findField('skin_color').getOptions()"
              :field="talentModel.findField('skin_color')"
            />
          </div>
          <div class="col-12 col-md-4">
            <component
              :is="talentModel.findField('eyes_color').getComponent()"
              :options="talentModel.findField('eyes_color').getOptions()"
              :field="talentModel.findField('eyes_color')"
            />
          </div>
          <div class="col-12 col-md-4">
            <component
              :is="talentModel.findField('hair_color').getComponent()"
              :options="talentModel.findField('hair_color').getOptions()"
              :field="talentModel.findField('hair_color')"
            />
          </div>

          <div
            v-if="talentModel.getAttr('gender', false).toString()==='2'"
            class="col-12 col-md-4"
          >
            <component
              :is="talentModel.findField('complexion').getComponent()"
              :options="talentModel.findField('complexion').getOptions()"
              :field="talentModel.findField('complexion')"
            />
          </div>
          <div
            v-if="talentModel.getAttr('gender', false).toString()==='1'"
            class="col-12 col-md-4"
          >
            <component
              :is="talentModel.findField('measurement').getComponent()"
              :options="talentModel.findField('measurement').getOptions()"
              :field="talentModel.findField('measurement')"
            />
          </div>

        </div>

        <div class="row">
          <div class="col-12 col-md-4">
            <component
              :is="talentModel.findField('abilities').getComponent()"
              :options="talentModel.findField('abilities').getOptions()"
              :field="talentModel.findField('abilities')"
            />
          </div>

          <!--          <div class="col-12 col-md-3">
            <component
              :is="talentModel.findField('languages').getComponent()"
              :options="talentModel.findField('languages').getOptions()"
              :field="talentModel.findField('languages')"
            />
          </div>-->

          <div class="col-12 col-md-4">
            <component
              :is="talentModel.findField('destinations').getComponent()"
              :options="talentModel.findField('destinations').getOptions()"
              :field="talentModel.findField('destinations')"
            />
          </div>

          <div class="col-12 col-md-4">
            <component
              :is="talentModel.findField('model_types').getComponent()"
              :options="talentModel.findField('model_types').getOptions()"
              :field="talentModel.findField('model_types')"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <component
              :is="talentModel.findField('description').getComponent()"
              :options="talentModel.findField('description').getOptions()"
              :field="talentModel.findField('description')"
            />
          </div>
        </div>

        <hr>

        <div class="row">
          <div class="col-12 col-md-2">
            <b-card
              v-if="talentModel.getAttr('multimedia', false).length>0"
              overlay
              :img-src="talentModel.getAttr('multimedia', false)[0].getAttr('file_url')"
            >

              <div
                :class="{'image-verified': imagesVerified.includes(talentModel.getAttr('multimedia', false)[0].getAttr('id')), 'image-rejected': imagesRejected.includes(talentModel.getAttr('multimedia', false)[0].getAttr('id'))}"
                style="width: 100%;height: 100%;position: absolute; left: 0; top:0;padding:10px;"
              >
                <span class="badge badge-primary">
                  Imagen principal
                </span>

                <div
                  style="position:absolute; bottom:10px;left:0; display:flex; width:100%;flex-direction: column"
                >

                  <!--                  <div
                    class="btn-group"
                  >
                    <a
                      :href="talentModel.getAttr('multimedia', false)[0].getAttr('file_url')"
                      target="_blank"
                      class="btn btn-flat-info"
                    >
                      <feather-icon icon="DownloadIcon" />
                    </a>

                    <button
                      class="btn btn-flat-info"
                    >
                      <feather-icon icon="UploadIcon" />
                    </button>
                  </div>-->

                  <div
                    class="btn-group"
                  >
                    <button
                      class="btn btn-flat-primary"
                      :disabled="imagesVerified.includes(talentModel.getAttr('multimedia', false)[0].getAttr('id'))"
                      @click="verifyImage(talentModel.getAttr('multimedia', false)[0].getAttr('id'))"
                    >
                      <feather-icon icon="CheckIcon" />
                    </button>

                    <button
                      class="btn btn-flat-danger"
                      :disabled="imagesRejected.includes(talentModel.getAttr('multimedia', false)[0].getAttr('id'))"
                      @click="rejectedImage(talentModel.getAttr('multimedia', false)[0].getAttr('id'))"
                    >
                      <feather-icon icon="TrashIcon" />
                    </button>
                  </div>

                </div>

              </div>

            </b-card>
          </div>

          <div
            v-for="photo of talentModel.getAttr('model_agent_photos', false)"
            :key="photo.getAttr('id')"
            class="col-12 col-md-2"
          >
            <b-card
              overlay
              :img-src="photo.getAttr('file_url')"
            >

              <div
                :class="{'image-verified': imagesVerified.includes(photo.getAttr('id')), 'image-rejected': imagesRejected.includes(photo.getAttr('id'))}"
                style="width: 100%;height: 100%;position: absolute; left: 0; top:0;padding:10px;"
              >
                <div
                  style="position:absolute; bottom:10px;left:0; display:flex; width:100%;flex-direction: column"
                >

                  <!--                  <div
                    class="btn-group"
                  >
                    <a
                      :href="photo.getAttr('file_url')"
                      target="_blank"
                      class="btn btn-flat-info"
                    >
                      <feather-icon icon="DownloadIcon" />
                    </a>

                    <button
                      class="btn btn-flat-info"
                    >
                      <feather-icon icon="UploadIcon" />
                    </button>
                  </div>-->

                  <div
                    class="btn-group"
                  >
                    <button
                      class="btn btn-flat-primary"
                      :disabled="imagesVerified.includes(photo.getAttr('id'))"
                      @click="verifyImage(photo.getAttr('id'))"
                    >
                      <feather-icon icon="CheckIcon" />
                    </button>

                    <button
                      class="btn btn-flat-danger"
                      :disabled="imagesRejected.includes(photo.getAttr('id'))"
                      @click="rejectedImage(photo.getAttr('id'))"
                    >
                      <feather-icon icon="TrashIcon" />
                    </button>
                  </div>

                </div>

              </div>

            </b-card>
          </div>
        </div>
      </div>
    </b-modal>

    <button
      class="btn btn-sm btn-secondary mt-1"
      @click="showModal"
    >
      Verificar
    </button>
  </div>
</template>

<script>
import { BModal, BSpinner, BCard } from 'bootstrap-vue'
import ModelAgent from '@/dc-it/models/ModelAgent'
import GeneralService from '@/dc-it/services/GeneralService'

export default {
  name: 'ModelAgentVerifyModal',
  components: { BModal, BSpinner, BCard },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loading: true,
    talentModel: null,
    imagesVerified: [],
    imagesRejected: [],
    // token: null,
  }),
  methods: {
    showModal() {
      this.$refs['agent-model-verify-modal'].show()
    },
    openModal() {
      ModelAgent.where('id', '=', this.id).get().then(agents => {
        if (agents.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.talentModel = agents[0]
          this.loading = false
        } else {
          this.$bvToast.toast('No se encontró el talento', {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
          this.$bvModal.hide('agent-model-verify-modal')
        }
      })
    },
    closeModal() {
      this.talentModel = null
    },
    async verify(e) {
      e.preventDefault()

      const countImages = this.talentModel.getAttr('multimedia', false).length + this.talentModel.getAttr('model_agent_photos', false).length
      if (countImages !== (this.imagesVerified.length + this.imagesRejected.length)) {
        this.$bvToast.toast('No se han verificado todas las imágenes', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        return
      }
      const mainImage = this.talentModel.getAttr('multimedia', false)[0]
      if (!this.imagesVerified.includes(mainImage.getAttr('id'))) {
        this.$bvToast.toast('No se ha verificado la imagen principal, no se puede avanzar', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
        return
      }

      this.loading = true

      const promises = []
      for (let i = 0; i < this.imagesRejected.length; i += 1) {
        const photo = this.talentModel.getAttr('model_agent_photos', false).find(m => m.getAttr('id') === this.imagesRejected[i])
        promises.push(photo.delete())
      }

      await Promise.all(promises)

      GeneralService.getInstance().save('model_agents/verify', this.talentModel.toJSON()).then(response => {
        if (response.result === 'success') {
          this.$bvToast.toast('Talento verificado', {
            title: 'Éxito',
            variant: 'success',
            solid: true,
          })
          this.$bvModal.hide('agent-model-verify-modal')
          this.$emit('verified')
        } else {
          this.$bvToast.toast('No se pudo verificar el talento', {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
        }
        this.loading = false
      })
    },
    verifyImage(id) {
      if (this.imagesRejected.includes(id)) {
        this.imagesRejected.splice(this.imagesRejected.indexOf(id), 1)
      }
      this.imagesVerified.push(id)
    },
    rejectedImage(id) {
      if (this.imagesVerified.includes(id)) {
        this.imagesVerified.splice(this.imagesVerified.indexOf(id), 1)
      }
      this.imagesRejected.push(id)
    },
  },
}
</script>

<style scoped>

.image-verified {
  background: rgba(0, 255, 0, 0.2);
}

.image-rejected {
  background: rgba(255, 0, 0, 0.2);
}

</style>
